<template>
  <div></div>
</template>

<script>
import axios from 'axios';

export default {
  name: "HealthCheck",
  data() {
    return {
      healthCheckUrl: ""
    };
  },
  methods: {
    doHealthCheck() {
      axios.get(this.healthCheckUrl)
          .then(() => {
            this.$emit('callback', 'ready');
          })
          .catch(() => {
            this.$emit('callback', 'error');
          });
    },
    setHealthCheck(input) {
      this.$emit('callback', input);
    }
  },
  mounted() {
    console.log(this.$root, this.$root.useStaticData);
    this.healthCheckUrl = this.$root.useStaticData ? this.$root.endpoint : "https://os-" + process.env.VUE_APP_ENV_VAR + "-api.mississauga.ca/api/v1/electionresult";
    this.setHealthCheck('loading');
    this.doHealthCheck();
  }
};
</script>